import { Paper, Typography } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import MultipleChoiceQuestion from '../../../components/MultipleChoiceQuestion';
import logo from '../../../assets/images/advance-logo.png';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

export default function TestPage({ params }) {
  const testId = params[`testId`];
  const [validId, setValidId] = useState(true);
  const [title, setTitle] = useState('');
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [incorrect, setIncorrect] = useState([]);
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [testInfo, setTestInfo] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onAnswer = (question, answer, correct, weight) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [question]: { question, answer, correct, weight },
    });
  };

  const onSubmit = async () => {
    const validEmail = email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (!validEmail) {
      alert('Please enter a valid email address, before submitting.');
      return;
    }
    setSubmitting(true);
    let score = 0;
    let badAnswers = [];
    for (const a in selectedAnswers) {
      if (selectedAnswers[a].correct) score += selectedAnswers[a].weight;
      else badAnswers.push(selectedAnswers[a].question);
    }
    if (score >= testInfo.passing_score) {
      try {
        await axios.post(`${API_URL}/saveTest`, {
          score,
          email,
          testId,
          numQuestions: testInfo.questions.length,
          userResponses: selectedAnswers,
        });
        setMessage(true);
      } catch (err) {
        console.log(err);
      }
    } else {
      alert('Unfortunately you did not pass, please revisit the questions in red and try again.');
      setIncorrect(badAnswers);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    (async () => {
      try {
        if (localStorage.getItem('token')) {
          const userData = jwt_decode(localStorage.getItem('token'));
          setEmail(userData.email);
          setLoggedIn(true);
        }
        const testRes = await axios.get(`${API_URL}/test/${testId}`);
        setTestInfo(testRes.data);
        setTitle(testRes.data.title);
      } catch (err) {
        setValidId(false);
      }
    })();
  }, []);

  const Questions = () => {
    if (!validId) return <p>Please select a test</p>;
    if (!testInfo) return <p>Loading Questions...</p>;
    return testInfo.questions.map((q, index) => (
      <MultipleChoiceQuestion key={index} question={q} index={index + 1} onAnswer={onAnswer} incorrect={incorrect} />
    ));
  };

  const confirmationMessage = () => {
    window.scrollTo(0, 0);
    return (
      <div style={{ marginTop: 110, height: '100%', maxWidth: 1200 }}>
        <h1>Congratulations you Passed!</h1>
        <p>Your results have been sent to the email you provided.</p>
        <p>
          Please check your inbox to get your certificate, view your account, and more. If you do not see the email in your inbox, please
          check your spam folder.
        </p>
        <div style={{ zIndex: 21 }}>
          <TestSelector />
        </div>
      </div>
    );
  };

  const TestSelector = () => {
    const testSelected = (id) => {
      if (id == '') return;
      window.location.href = `/FL/Veterinarians/${id}`;
    };
    return (
      <div style={{ width: '100%' }}>
        <h4>{message ? 'Take Another Test' : 'Choose A Test'}</h4>
        <select defaultValue={message ? '' : testId} onChange={(e) => testSelected(e.target.value)}>
          <option value="">Select a Test</option>
          <option value="FLVET1">1. Dispensing Controlled Substances (1-hour)</option>
          <option value="FLVET2">2. Laws & Rules for FL Vets (2-hours)</option>
        </select>
      </div>
    );
  };

  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) return null;
  const height = window.innerHeight;

  return (
    <Layout fullMenu style={{ overflow: 'hidden' }} logoUrl="/FL/Veterinarians">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 50,
          padding: 20,
          backgroundColor: '#2e3842',
          width: '100%',
          color: 'white',
          overflow: 'hidden',
        }}
      >
        {!message ? (
          <>
            <div
              style={{
                width: 640,
                maxWidth: '100%',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginTop: 100,
                marginBottom: 30,
              }}
            >
              {TestSelector()}
            </div>

            {testInfo && (
              <Paper
                elevation={3}
                style={{
                  width: 640,
                  maxWidth: '100%',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  display: 'flex',
                  marginTop: 0,
                  backgroundColor: '#fee9d9',
                  marginBottom: 30,
                  padding: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <Paper
                  elevation={3}
                  style={{
                    overflow: 'hidden',
                    marginBottom: 20,
                  }}
                >
                  <img src={logo} height={220} width={'95%'} style={{ objectFit: 'contain' }} />
                  <Typography variant="h6" style={{ textAlign: 'center' }} color="black">
                    {title}
                  </Typography>
                </Paper>
                {!loggedIn && (
                  <Paper variant="outlined" style={{ padding: 20, marginBottom: 20 }}>
                    <Typography style={{ marginBottom: 0 }}>What's your Email?</Typography>
                    <input type="text" onChange={(e) => setEmail(e.target.value)} />
                  </Paper>
                )}
                {Questions()}
                <button
                  onClick={onSubmit}
                  disabled={submitting}
                  style={{
                    backgroundColor: '#ff6400',
                    alignSelf: 'flex-start',
                  }}
                >
                  {submitting ? 'Submitting...' : 'Submit'}
                </button>
              </Paper>
            )}
          </>
        ) : (
          confirmationMessage()
        )}

        {/* {!message && (
          <div
            style={{
              position: 'absolute',
              top: height + 200,
              zIndex: 20,
              backgroundColor: '#2e3842',
              width: '100%',
              height: 200,
              borderRadius: -20,
            }}
          ></div>
        )} */}
      </div>
    </Layout>
  );
  // When visiting a route like `image/hello/world`,
  // the value of `param` is `hello/world`.
}
